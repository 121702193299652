import { Controller } from 'stimulus'
import Flickity from 'flickity-fade'

export default class extends Controller {
  static targets = ['slider']

  connect () {
    this.cfg = {
      wrapAround: true,
      autoPlay: false,
      draggable: true,
      adaptiveHeight: false,
      prevNextButtons: false,
      pageDots: false
    }

    if (this.hasSliderTarget) {
      this.instance = new Flickity(this.sliderTarget, this.cfg)
    }
  }

  prevSlide () {
    console.log('PREVIOUS')
    this.instance.previous(true)
  }

  nextSlide () {
    console.log('NEXT')
    this.instance.next(true)
  }
}
