import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['video']

  play (e) {
    if (this.videoTarget && typeof this.videoTarget.play === 'function') {
      this.videoTarget.setAttribute('controls', true)
      this.videoTarget.play()
      e.currentTarget.setAttribute('hidden', true)
    }
  }
}
