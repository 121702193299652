import { Controller } from 'stimulus'

const Kernel = window.Kernel

export default class extends Controller {
  static classes = ['active']
  static values = { target: String }

  connect () {
    if (!this.hasActiveClass || !this.hasTargetValue) {
      Kernel.log('Toggle controller require active class and target value')
    }

    this.target = document.querySelector(this.targetValue)
  }

  toggle (e) {
    e.preventDefault()

    if (this.target) {
      this.target.classList.toggle(this.activeClass)
    }
  }
}
