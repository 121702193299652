import { Controller } from 'stimulus'
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import * as focusTrap from 'focus-trap'

const Kernel = window.Kernel

export default class extends Controller {
  static classes = ['active', 'content']
  static values = { target: String }

  connect () {
    if (!this.hasTargetValue) {
      Kernel.log('Toggle controller require active class and target value')
    }

    // Elements
    this.e = {
      target: document.querySelector(this.targetValue),
      content: document.querySelector(this.hasContentClass ? '.' + this.contentClass : '.content'),
      toggles: Array.from(document.querySelectorAll(`[aria-controls="${document.querySelector(this.targetValue).getAttribute('id')}"]`))
    }

    // Classes
    this.c = {
      active: this.hasActiveClass ? this.activeClass : 'modal--active'
    }

    // Data
    this.d = {
      name: this.e.target ? this.e.target.getAttribute('aria-label') : this.e.targetValue
    }

    this.focusTrap = this.e.target.trap
      ? this.e.target.trap
      : focusTrap.createFocusTrap(this.e.target, {
        onActivate: () => Kernel.emitEvent('modal.focustrap.activate', { name: this.d.name, target: this.e.target }),
        onDeactivate: () => Kernel.emitEvent('modal.focustrap.deactivate', { name: this.d.name, target: this.e.target })
      })

    this.e.target.trap = this.focusTrap
  }

  disconnect () {
    this.focusTrap.deactivate()
    clearAllBodyScrollLocks()
  }

  isActive () {
    return this.e.target.getAttribute('aria-hidden') === 'false'
  }

  toggle (e) {
    this.isActive() ? this.hide(e) : this.show(e)
  }

  show (e) {
    this.e.toggles.forEach(toggle => toggle.setAttribute('aria-expanded', true))

    this.e.target.classList.add(this.c.active)
    this.e.target.setAttribute('aria-hidden', false)
    this.e.content.setAttribute('aria-hidden', true)

    disableBodyScroll(this.e.target)
    this.focusTrap.activate()

    Kernel.emitEvent('modal.show', { name: this.d.name, target: this.e.target })
  }

  hide (e) {
    this.e.toggles.forEach(toggle => toggle.setAttribute('aria-expanded', false))

    this.e.target.classList.remove(this.c.active)
    this.e.target.setAttribute('aria-hidden', true)
    this.e.content.setAttribute('aria-hidden', false)

    enableBodyScroll(this.e.target)
    this.focusTrap.deactivate()

    Kernel.emitEvent('modal.hide', { name: this.d.name, target: this.e.target })
  }
}
