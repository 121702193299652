import { Controller } from 'stimulus'

export default class extends Controller {
  connect () {
    this.e = {
      active: this.element.querySelector('.link--active'),
      note: this.element.querySelector('.line__note')
    }

    this.c = {
      active: 'line__note--active',
      init: 'line__note--init',
      expanded: 'navigation__children--expanded'
    }

    this.s = {
      noDefaultState: false
    }

    this.setDefaultState()
  }

  moveNote (e) {
    this.moveNoteToElement(e.currentTarget)
  }

  moveNoteToElement (el) {
    this.e.note.classList.add(this.c.init)
    el.parentNode.style.setProperty('--offset-x', el.offsetLeft + (el.offsetWidth / 2) + 'px')
  }

  setDefaultState (e) {
    if (!this.s.noDefaultState && this.e.active && (!e || e.fromElement === this.element)) {
      this.moveNoteToElement(this.e.active)
      this.e.note.classList.add(this.c.active)
    }
  }

  toggleChildren (e) {
    if (e.currentTarget.dataset && e.currentTarget.dataset.id) {
      const target = this.element.parentNode.querySelector(`[data-parent-id="${e.currentTarget.dataset.id}"]`)

      if (target) {
        target.classList.toggle(this.c.expanded)
        this.s.noDefaultState = target.classList.contains(this.c.expanded)
      }
    }
  }
}
