import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['list', 'more']

  connect () {
    this.pageItems = parseInt(this.element.dataset.navItems) || 12
    this.hiddenItems = Array.from(this.element.querySelectorAll('[hidden]'))

    if (this.hiddenItems.length <= 0) {
      this.moreTarget.style.display = 'none'
    }
  }

  more () {
    for (let i = 0; i < this.pageItems; ++i) {
      if (this.hiddenItems.length && this.hiddenItems.length > 0) {
        this.hiddenItems.shift().removeAttribute('hidden')
      }
    }

    if (this.hiddenItems.length <= 0) {
      this.moreTarget.style.display = 'none'
    }
  }
}
