const Kernel = window.Kernel

const Transition = {
  element: document.querySelector('.transition--fade'),

  wait: callback => {
    const listener = e => {
      if (e.target === Transition.element) {
        Transition.element.removeEventListener(window.transitionEnd, listener)
        callback()
      }
    }

    Transition.element.addEventListener(window.transitionEnd, listener)
  },

  leave: data => {
    return new Promise(resolve => {
      Kernel.emitEvent('component.pagetransition.start')
      Transition.wait(resolve)
      Transition.element.classList.add('transition--active')
    })
  },

  afterLeave: data => {
    Kernel.emitEvent('component.pagetransition.leave')
    if (data.current && data.current.container) {
      data.current.container.remove()
    }

    Kernel.emitEvent('component.pagetransition.ready')
  },

  enter: data => {
    return new Promise(resolve => {
      Kernel.emitEvent('component.pagetransition.enter')
      Transition.wait(resolve)
      Transition.element.classList.remove('transition--active')
    })
  },

  afterEnter: data => {
    Kernel.emitEvent('component.pagetransition.end')
  }
}

export const FadePageTransition = {
  name: 'fade',
  leave: Transition.leave,
  afterLeave: Transition.afterLeave,
  enter: Transition.enter,
  afterEnter: Transition.afterEnter
}
