import { Controller } from 'stimulus'

export default class extends Controller {
  connect () {
    this.s = {
      tolerance: 150,
      windowHeight: window.innerHeight || document.documentElement.clientHeight
    }
  }

  update (e) {
    this.s.windowHeight = (window.innerHeight || document.documentElement.clientHeight)
    const itemBox = this.element.getBoundingClientRect()

    if (this.itemInViewport(itemBox)) {
      const itemRelPos = this.s.windowHeight - itemBox.top + this.s.tolerance
      const itemOffset = Math.round(itemRelPos)

      this.element.style.setProperty('--parallax', itemOffset + 'px')
    }
  }

  getOffsetTop () {
    return window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
  }

  itemInViewport (itemBox) {
    // http://stackoverflow.com/questions/325933/determine-whether-two-date-ranges-overlap
    return (itemBox.top - this.s.tolerance <= this.s.windowHeight) && ((itemBox.top + itemBox.height + this.s.tolerance) >= 0)
  }
}
